import React from "react";
import { connect } from "react-redux";

class Translation extends React.Component {
  constructor(props) {
    super(props);
    const translations = require("./" + this.props.lang + ".json");
    this.state = {
      translations,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const translations = require("./" + props.lang + ".json");
    this.setState({ translations });
  }

  render() {
    return this.state.translations[this.props.trl] || null;
  }
}

const mapStateToProps = state => ({
  lang: state.lang,
});

export default connect(mapStateToProps)(Translation);
