import React from "react";
import { connect } from "react-redux";
import { changeLang } from "../../reducers/actions/index";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./langSelector.scss";
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';
class LangSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
    }
  }

  render() {
    return (
      <div className="lang_selector">
        <div className="select">
          <span className={this.state.dropdownOpen ? "active" : ""}>
            {this.props.lang.toUpperCase()}
          </span>
          <div className="icon-container" onClick={() => this.toggleDropdown()}>
            {!this.state.dropdownOpen && <ExpandLessIcon />}
            {this.state.dropdownOpen && <ExpandMoreIcon />}
          </div>
        </div>
        <div
          className={this.state.dropdownOpen ? "dropdown active" : "dropdown"}
        >
          <div onClick={() => this.selectOption("NL")}>{"NL"}</div>
          <div onClick={() => this.selectOption("FR")}>{"FR"}</div>
          <div onClick={() => this.selectOption("DE")}>{"DE"}</div>
          <div onClick={() => this.selectOption("EN")}>{"EN"}</div>
        </div>
      </div>
    )
  }

  getClassName(lang) {
    return lang === this.props.lang ? "active" : "default"
  }

  toggleDropdown() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen })
  }

  selectOption(option) {
    this.setState({ selectedOption: option })
    this.toggleDropdown()
    this.props.changeLang(option.toLowerCase())
  }
}

const mapStateToProps = state => ({
  lang: state.lang,
})

const mapDispatchToProps = () => {
  return {
    changeLang,
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(LangSelector)
